module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"disableAutoprefixing":false,"disableMinification":false,"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../plugins/custom-themes/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress-experimental/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://sinai-park-house.staging.brew-systems.co.uk/graphql","schema":{"perPage":20,"timeout":300000,"requestConcurrency":3,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"html":{"imageMaxWidth":1440,"fallbackImageMaxWidth":1440,"imageQuality":80,"useGatsbyImage":true,"createStaticFiles":true},"excludeFieldNames":["comments","blocksJSON","previewBlocks","previewBlocksJSON"],"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M3C2KNN","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"ga_code":""}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
