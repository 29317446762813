import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import themeData from "./muiTheme.json";
import themeOptions from "../../../.brew-cache/theme.json";
import {modifyMui} from "../modifyMui";

// Styling Images

import sepImage from '../../../src/images/divider.png';
import accordOpen from '../../../src/images/accord-open.png';
import accordClose from '../../../src/images/accord-close.png';
import purplePaper from '../../../src/images/purple-paper.jpg';
import brandStamp from '../../../src/images/brand-stamp.png';
import linkArrow from '../../../src/images/arrow-right-purple.png';
import linkArrowWhite from '../../../src/images/arrow-right-white.png';
import floral from '../../../src/images/floral-texture.png';


const themeName = 'Brew Core Theme';
const modifiedTheme = modifyMui(themeData, themeOptions);
let theme = createMuiTheme({ ...modifiedTheme, themeName });
theme = responsiveFontSizes(theme, { factor: 3 });

// Custom theme styles here or import them from wherever if they need to be broken

export default theme;

theme.headerTheme = {
    '&.MuiTypography-h1': {
        fontFamily: theme.headerFont,
        fontSize: 36,
        lineHeight: 1.5,
        letterSpacing: '-.016em',
        fontWeight: '900',
        [theme.breakpoints.up('md')]: {
            fontSize: 72,
        }
    },
    '&.MuiTypography-h2': {
        fontFamily: theme.headerFont,
        fontSize: 32,
        lineHeight: 1.5,
        letterSpacing: '-.008em',
        fontWeight: '700',
        [theme.breakpoints.up('md')]: {
            fontSize: 64,
        }
    },
    '&.MuiTypography-h3': {
        fontFamily: theme.headerFont,
        fontSize: 24,
        lineHeight: 1.5,
        fontWeight: '700',
        [theme.breakpoints.up('md')]: {
            fontSize: 48,
        }
    },
    '&.MuiTypography-h4': {
        fontFamily: theme.headerFont,
        fontSize: 16,
        lineHeight: 1.5,
        fontWeight: 'normal',
        [theme.breakpoints.up('md')]: {
            fontSize: 32,
        }
    },
    '&.MuiTypography-h5': {
        fontFamily: theme.headerFont,
        fontSize: 16,
        lineHeight: 1.5,
        fontWeight: '500',
        [theme.breakpoints.up('md')]: {
            fontSize: 24,
        }
    },
    '&.MuiTypography-h6': {
        fontFamily: theme.headerFont,
        fontSize: 16,
        lineHeight: 1.5,
        fontWeight: '500',
        [theme.breakpoints.up('md')]: {
            fontSize: 18,
        }
    },
    '&.contained': {
        [theme.breakpoints.up('md')]: {
            maxWidth: '83.333333%',
            margin: '0 auto',
            padding: 12,
        }
    }
}

theme.paragraphTheme = {
    fontSize: 16,
    lineHeight: 2,
    letterSpacing: '0.032em',
    [theme.breakpoints.up('md')]: {
        fontSize: 18,
    },
    '&.contained': {
        [theme.breakpoints.up('md')]: {
            maxWidth: '83.333333%',
            margin: '0 auto',
            padding: 12,
        }
    },
    '&.indented': {
        padding: '0 4.1666666% 0 10.25%',
    }
}

theme.bannerMessageTheme = {
    '& > .gatsby-image-wrapper': {
        '&::after': {
            display: 'none'
        },
    },
    '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5': {
        top: '50%',
        transform: 'translateY(-50%)',
        padding: '0 32px',
        margin: 0,
        lineHeight: 1.33,
        fontSize: 32,
        maxWidth: 400,
        fontWeight: 'bold',
        height: 'fit-content',
        '&::before': {
            content: '""',
            width: 100,
            height: 100,
            backgroundColor: theme.palette.primary.main,
            display: 'block',
            position: 'absolute',
            transform: 'rotate(45deg)',
            zIndex: -1,
            left: -40,
            top: 20,
            boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
            opacity: 0.75,
            [theme.breakpoints.up('md')]: {
                width: 200,
                height: 200,
                top: 40,
                left: -100
            }
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 48,
            padding: '0 80px',
            maxWidth: 500,
        }
    },
    '&.secondary-accent': {
        '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5': {
            '&::before': {
                backgroundColor: theme.palette.secondary.main,
            },
        },
    },
    '&.tertiary-accent': {
        '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5': {
            '&::before': {
                backgroundColor: theme.palette.tertiary.main
            },
        },
    }
}

theme.hrTheme = {
    height: 15,
    border: 'none !important',
    backgroundImage: 'url(' + sepImage + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
}

theme.accordionInnerTheme = {
    '& > div': {
        boxShadow: 'none',
        '& .MuiAccordionSummary-root': {
            padding: 0,
            backgroundColor: theme.palette.background.secondary,
            boxShadow: '0 4px 6px rgba(0,0,0,0.25)',
            marginBottom: 16,
            minHeight: '0px !important',
            borderRadius: 4,
            transition: 'background-color .3s ease-in-out',
            '& .MuiAccordionSummary-expandIcon': {
                height: 15,
                width: 19,
                backgroundImage: 'url(' + accordOpen + ')',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                marginRight: 20,
                transition: '.3s ease-in-out transform',
                '& svg': {
                    display: 'none',
                }
            },
            '&.Mui-expanded': {
                backgroundColor: theme.palette.primary.main,
                '& .MuiAccordionSummary-content': {
                    '& h1, & h2, & h3, & h4, & h5': {
                        color: theme.palette.primary.contrastText,
                    }
                },
                '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
                    backgroundImage: 'url(' + accordClose + ')',
                    transform: 'rotate(90deg)',
                },
            },
            '& .MuiAccordionSummary-content': {
                marginTop: 0,
                marginBottom: 0,
                '& h1, & h2, & h3, & h4, & h5': {
                    padding: '16px 32px',
                    linHeight: 1.75,
                    fontFamily: theme.bodyFont,
                    color: theme.palette.primary.main,
                    fontSize: 17,
                    transition: 'color .3s ease-in-out',
                }
            }
        },
        '& .MuiAccordionDetails-root': {
            display: 'block',
            padding: 32,
            [theme.breakpoints.up('md')]: {
                padding: 72,
            },
            '& h1, & h2, & h3, & h4, & h5': {
                marginBottom: 18,
                color: theme.palette.primary.main,
            }
        }
    }
}

theme.imageContentRowsInnerTheme = {
    backgroundColor: 'transparent',
    alignItems: 'flex-start',
    width: '100%',
    margin: 0,
    transform: 'none',
    '& .gatsby-image-wrapper': {
        zIndex: 2,
    },
    [theme.breakpoints.up('md')]: {
        '& .gatsby-image-wrapper': {
            width: 'calc(50vw - 65px) !important',
            maxWidth: 'calc(50vw - 65px) !important',
        }
    },
    '& .image-wrap': {
        paddingLeft: 64,
        paddingTop: 30,
        marginRight: -34,
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            paddingTop: 90,
            paddingBottom: 90,
            paddingLeft: '6.25%',
        },
        '&::before': {
            content: '""',
            display: 'block',
            width: 60,
            height: 60,
            backgroundColor: theme.palette.primary.main,
            position: 'absolute',
            top: 0,
            left: 34,
            position: 'absolute',
            zIndex: 1,
            transform: 'rotate(45deg)',
            [theme.breakpoints.up('md')]: {
                width: 128,
                height: 128,
                top: 26,
                left: 16,
            },
        }
    },
    '& .con-wrap': {
        [theme.breakpoints.up('md')]: {
            padding: '24px 0 24px 12.5%',
        },
        '& h1, & h2, & h3, & h4, & h5, & h6': {
            marginLeft: -24,
        },
        '& p': {
            color: theme.palette.common.black,
        }
    },
    '&:nth-of-type(even)': {
        '& .image-wrap': {
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                justifyContent: 'flex-end',
                paddingLeft: 0,
                paddingRight: 80,
            },
            '&::before': {
                [theme.breakpoints.up('md')]: {
                    transform: 'rotate(-45deg)',
                    top: 26,
                    left: 'auto',
                    right: 16,
                },
            }
        },
    },
    '&.no-accent': {
        '& .image-wrap': {
            '&::before': {
                display: 'none',
            },
        },
    },
    '&.secondary-accent': {
        '& .image-wrap': {
            '&::before': {
                backgroundColor: theme.palette.secondary.main,
            },
        },
    },
    '&.tertiary-accent': {
        '& .image-wrap': {
            '&::before': {
                backgroundColor: theme.palette.tertiary.main
            },
        },
    }
}

theme.imageContentRowsTheme = {
    position: 'relative',
    width: '100%',
    margin: '0 0 36px',
    [theme.breakpoints.up('md')]: {
        margin: '0 0 36px',
    }
}

theme.twoCardIntroTheme = {
    padding: 0,
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.up('md')]: {
        padding: 0,
        paddingBottom: 150,
    },
    '&::before': {
        display: 'none'
    },
    '& .con-col': {
        marginBottom: 0,
        backgroundImage: 'url(' + purplePaper + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        paddingTop: 32,
        paddingBottom: 128,
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            float: 'none',
            width: '100%',
            paddingTop: 64,
            paddingBottom: 168,
        },
        [theme.breakpoints.up('lg')]: {
            width: '100%',
            paddingBottom: 240,
        },
        '&::before': {
            zIndex: -1,
            bottom: -100,
            width: '100%',
            right: '-8.3%',
            top: 120,
            content: '""',
            display: 'block',
            position: 'absolute',
            backgroundImage: 'url(' + brandStamp + ')',
            backgroundPosition: 'bottom right',
            backgroundSize: 49,
            [theme.breakpoints.up('md')]: {
                top: 240,
            },
        },
        '& *': {
            color: theme.palette.primary.contrastText,
        },
        '& h2.tag-title': {
            fontFamily: theme.headerFont,
            fontSize: 36,
            lineHeight: 1.1,
            letterSpacing: '-.016em',
            fontWeight: '900',
            marginBottom: 24,
            marginTop: 0,
            [theme.breakpoints.up('md')]: {
                marginBottom: 48,
                fontSize: 72,
            },
        },
        '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5': {
            fontFamily: theme.bodyFont,
            fontSize: 18,
            lineHeight: 1.33,
            letterSpacing: 0,
            fontWeight: '500',
            marginBottom: 24,
            [theme.breakpoints.up('md')]: {
                fontSize: 24,
            }
        }
    },
    '& .cards': {
        marginBottom: 0,
        marginTop: -84,
        position: 'relative',
        zIndex: 2,
        [theme.breakpoints.up('md')]: {
            float: 'none',
            width: '100%',
            marginTop: -136,
            '& .MuiGrid-grid-md-12': {
                display: 'none',
            }
        },
        [theme.breakpoints.up('lg')]: {
            width: '100%',
        },
        '& .card': {
            width: '100%',
            float: 'none',
            marginLeft: 0,
            height: '100%',
            '& .con-wrap': {
                position: 'initial',
                paddingBottom: 0,
            },
            '& .links': {
                position: 'absolute',
                bottom: 36,
            }
        },
        '& .alt-card': {
            '& .card': {
                backgroundColor: theme.palette.tertiary.main,
            }
        }
    },
}

theme.cardBlockTheme = {
    paddingTop: 32,
    paddingRight: 32,
    paddingBottom: 8,
    borderRadius: 16,
    boxShadow: '0 16px 26px rgba(0,0,0,0.2)',
    backgroundColor: theme.palette.secondary.main,
    '& .con-wrap': {
        paddingRight: 0,
        [theme.breakpoints.up('md')]: {
            padding: '32px 24px 32px 48px',
        },
        '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5': {
            fontSize: 24,
            lineHeight: 1.5,
            fontWeight: 500,
            color: theme.palette.primary.main,
            letterSpacing: '0.08em',
            marginBottom: 16,
            textTransform: 'none',
            [theme.breakpoints.up('md')]: {
                fontSize: 32,
            },
        },
        '& p': {
            fontSize: 16,
            lineHeight: 1.5,
            letterSpacing: '0.032em',
            color: theme.palette.primary.main,
            [theme.breakpoints.up('md')]: {
                fontSize: 18,
            },
        },
        '& .cta': {
            marginLeft: 0,
            fontSize: 14,
            lineHeight: 1.5,
            letterSpacing: '0',
            textDecoration: 'underline !important',
            fontFamily: theme.bodyFont,
            fontWeight: '500',
            color: theme.palette.primary.main,
            whiteSpace: 'nowrap',
            [theme.breakpoints.up('md')]: {
                fontSize: 17,
            },
            '&::after': {
                display: 'inline-block',
                content: '""',
                marginLeft: 24,
                width: 31,
                height: 19,
                backgroundImage: 'url(' + linkArrow + ')',
                backgroundSize: 'cover',
                verticalAlign: 'middle',
                transition: 'margin-left .3s ease-in-out',
            },
            '&:hover, &:focus': {
                '&::after': {
                    marginLeft: 32,
                },
            }
        }
    },
    '&.primary-bg': {
        backgroundColor: theme.palette.primary.main,
        '& .con-wrap *, ': {
            color: theme.palette.primary.contrastText,
            '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5': {
                color: theme.palette.primary.contrastText,
            }
        },
    },
    '&.tertiary-bg': {
        backgroundColor: theme.palette.tertiary.main,
    }
}

theme.pageTitleTheme = {
    marginTop: 80,
    [theme.breakpoints.up('md')]: {
        marginTop: 128,
    },
    '&.light-text': {
        '& h1, & h2, & h3, & h4, & h5, & h6' : {
            color: theme.palette.primary.contrastText,
        },
    },
    '&.dark-text': {
        '& h1, & h2, & h3, & h4, & h5, & h6' : {
            color: theme.palette.primary.main,
        },
    }
}

theme.twoColWrapperTheme = {

}

theme.twoColInnerTheme = {
    '& > .MuiTypography-h1, & > .MuiTypography-h2': {
        fontFamily: theme.headerFont,
        fontSize: 32,
        lineHeight: 1.5,
        letterSpacing: '-.026em',
        fontWeight: '900',
        color: theme.palette.primary.main,
        [theme.breakpoints.up('md')]: {
            fontSize: 68,
        }
    },
    '& h1, & h2, & h3, & h4, & h5, & h6' : {
        marginBottom: 24,
        marginTop: 24,
        '&.indented': {
            padding: '0 0 0 12%',
        }
    },
    '& > div.button-group': {
        '&.indented': {
            padding: '0 8.333% 0 22%',
        }
    }, 
    '& > p': {
        marginBottom: 24,
        '&.indented': {
            padding: '0 8.333% 0 22%',
        }
    },
    '& figure.wp-block-image': {
        position: 'relative',
        '&::after': {
            content: '""',
            display: 'block',
            width: 75,
            height: 75,
            position: 'absolute',
            zIndex: -1,
            backgroundColor: theme.palette.primary.main,
            bottom: -36,
            right: -36,
            transform: 'rotate(-45deg)',
            [theme.breakpoints.up('md')]: {
                width: 150,
                height: 150,
                bottom: -75,
                right: -75,
            }
        },
        '&.top-accent': {
            '&::after': {
                top: -36,
                bottom: 'auto',
                [theme.breakpoints.up('md')]: {
                    bottom: 'autp',
                    top: -75,
                }
            }
        },
        '&.no-accent': {
            '&::after': {
                display: 'none',
            }
        },
        '&.secondary-accent': {
            '&::after': {
                backgroundColor: theme.palette.secondary.main,
            }
        },
        '&.tertiary-accent': {
            '&::after': {
                backgroundColor: theme.palette.tertiary.main,
            }
        }
    }
}

theme.featruredTextTheme = {

}

theme.imageLinkRowTheme = {
    '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5': {
        color: theme.palette.primary.main,
    },
    '&.primary-bg': {
        '&::before': {
            backgroundColor: theme.palette.primary.main,
        },
        '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5': {
            color: theme.palette.primary.contrastText,
        },
        '& .image-link-row-inner': {
            color: theme.palette.primary.contrastText,
            '& > span::after': {
                backgroundImage: 'url(' + linkArrowWhite + ')',
            },
        }
    },
    '&.secondary-bg': {
        '&::before': {
            backgroundColor: theme.palette.secondary.main,
        }
    },
    '&.tertiary-bg': {
        '&::before': {
            backgroundColor: theme.palette.tertiary.main,
        }
    }
}

theme.imageLinkRowInnerTheme = {
    color: theme.palette.primary.main,
    '& > span': {
        fontSize: 16,
        fontWeight: 500,
        whiteSpac: 'nowrap',
        [theme.breakpoints.up('md')]: {
            fontSize: 22,
        },
        '&:hover, &:focus': {
            '&::after': {
                marginLeft: 15,
            }
        },
        '&::after': {
            display: 'inline-block',
            content: '""',
            marginLeft: 10,
            width: 31,
            height: 19,
            backgroundImage: 'url(' + linkArrow + ')',
            backgroundSize: 'cover',
            verticalAlign: 'middle',
            transition: 'margin-left .3s ease-in-out',
        },
    }
}

theme.twoCardSplitTheme = {
    '& .card': {
        borderRadius: 0,
        [theme.breakpoints.up('md')]: {
            padding: '70px 90px',
            '& .con-wrap': {
                '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5': {
                    fontSize: 48,
                    fontWeight: '800',
                    letterSpacing: '0',
                    marginBottom: 18,
                },
                '& p': {
                    fontSize: 18,
                    lineHeight: 1.5,
                    letterSpacing: '0.032em',
                    marginBottom: 50,
                }
            }
        },
    }
}

theme.thickIntroTheme = {
    '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5': {
        color: theme.palette.primary.main,
        marginBottom: 32,
        [theme.breakpoints.up('md')]: {
            maxWidth: '87.5%',
            margin: '0 6.25% 32px',
        }
    },
    '& p': {
        [theme.breakpoints.up('md')]: {
            maxWidth: '87.5%',
            margin: '0 6.25% 32px',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '75%',
            margin: '0 12.5% 32px',
        }
    },
}

theme.cardRowTheme = {
    paddingTop: 80,
    '&::before': {
        zIndex: -1,
        top: 0,
        height: 330,
        width: '100%',
        left: 0,
        right: 0,
        content: '""',
        display: 'block',
        position: 'absolute',
        backgroundImage: 'url(' + brandStamp + ')',
        backgroundPosition: 'center top',
        backgroundSize: 49,
    },
    '& .cards': {
        [theme.breakpoints.up('md')]: {
            maxWidth: '87.5%',
            margin: '0 6.25% 32px',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '75%',
            margin: '0 12.5% 32px',
        },
        '& .card': {
            '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5': {
                fontFamily: theme.bodyFont,
                fontSize: 24,
            }
        }
    },
    '&.bottom-bg': {
        paddingTop: 0,
        paddingBottom: 80,
        '&::before': {
            top: 'auto',
            bottom: 0,
        }
    }
}

theme.topNavTheme = {
    backgroundColor: 'transparent',
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 10,
    width: '100%',
    maxWidth: 1250,
}

theme.blockPostBannerTheme = {
    marginTop: 80,
    [theme.breakpoints.up('md')]: {
        marginTop: 128,
    },
}

theme.calloutCtaTheme = {
    paddingTop: 50,
    [theme.breakpoints.up('md')]: {
        paddingTop: 120,
    },
    '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5': {
        color: theme.palette.primary.contrastText,
        marginBottom: 32,
    },
    '& p': {
        color: theme.palette.primary.contrastText,
    },
    '& p > a': {
        color: theme.palette.primary.contrastText,
        textDecoration: 'underline !important',
        transition: 'opacity .3s ease-in-out',
        '&:hover, &:focus': {
            opacity: .8,
            color: theme.palette.primary.contrastText,
        }
    },
    '& button': {
        marginTop: 24,
    },
    '& .gatsby-image-wrapper': {
        marginTop: 60,
        marginBottom: 20,
    },
    '&::before': {
        content: '""',
        backgroundImage: 'url(' + purplePaper + ')',
        position: 'absolute',
        width: '100vw',
        top: 0,
        bottom: 50,
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundSize: 'cover',
        [theme.breakpoints.up('md')]: {
            bottom: 110,
        },
    },
    '&::after': {
        content: '""',
        backgroundImage: 'url(' + floral + ')',
        position: 'absolute',
        width: '100vw',
        height: 50,
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundSize: 'contain',
        zIndex: -1,
        [theme.breakpoints.up('md')]: {
            height: 110,
        },
    }
}



theme.cardColumnsTheme = {
    '& .cards': {
        '& .card': {
            backgroundColor: 'transparent',
            borderRadius: 0,
            boxShadow: 'none',
            paddingTop: 0,
            paddingRight: 0,
            '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5': {
                fontFamily: theme.bodyFont,
                fontSize: 24,
            },
            '& .con-wrap': {
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 12,
                '& .min-height > p': {
                    color: theme.palette.text.primary,
                    fontSize: 16,
                }
            },
        }
    }
}